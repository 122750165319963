import React, {useState ,useRef, useEffect } from "react";

import '../App.css';
import {useInView} from "react-intersection-observer";
import Grid from '@material-ui/core/Grid';
import Box from '@mui/material/Box';
import MenuSection from "./MenuSection";
import MenuItem from "./MenuItem";

function Menu(props) {
  let sections = props.sections;
  const [activeSectionIndexes, setActiveSectionIndexes] = useState([]);

  useEffect(() => {
    console.log(sections);
  },[sections]);

  function handleSetActiveSectionIndex(index, active) {
    setActiveSectionIndexes(prevIndexes => {
      if (active) {
        return [...prevIndexes, index];
      } else {
        return prevIndexes.filter(i => i !== index);
      }
    });
  }

  return (
    <>
      <Grid sx={0} md={2} className="font-link">
        <Box
          sx={{ display: { xs: "none", md: "block" } }}
          style={{ position: "sticky", top: "100px" }}
        >
          <ul style={{ listStyle: "none", fontSize: ".875rem" }}>
            {sections.map((section, index) => (
              <li key={section.id}>
                {activeSectionIndexes.includes(index) ? (
                  <a
                    className="menuLink"
                    src={`#ref_${section.id}`}
                    style={{ borderBottom: "1px solid #009de0", color: "#009de0" }}
                  >
                    {section.title}
                  </a>
                ) : (
                  <a className="menuLink" href={`#${section.id}`}>
                    {section.title}
                  </a>
                )}
              </li>
            ))}
          </ul>
        </Box>
      </Grid>


      <Grid sx={12} md={6}>
        {props.sections.map((section, index) => (
          <Box key={index} id={`${section.id}`}>
            <MenuSection
              title={section.title}
              index={index}
              onSetActive={handleSetActiveSectionIndex}
            >
              {section.items.map((item, index) => (
                <MenuItem
                  key={index}
                  itemId={item.id}
                  sectionId={section.id}
                  title={item.title}
                  description={item.description}
                  price={item.price}
                  optionKinds={item.optionsKinds || []}
                  extrasKinds={item.extrasKinds || []}
                  img={item.img}
                  onClickFood={props.handleClickFood}
                />
              ))}
            </MenuSection>
          </Box>
        ))}
      </Grid>

    </>
  );
}

export default Menu;