import React, {useState ,useRef, useEffect } from "react";
import {useInView} from "react-intersection-observer";


export default function MenuSection (props){

	const [active, setActive] = useState(false);
	const { ref, inView } = useInView();
	const {title, index, children} = props;

	
	useEffect(() => {
		if (inView) {
		  props.onSetActive(props.index, true);
		  setActive(true);
		} else {
		  props.onSetActive(props.index, false);
		  setActive(false);
		}
	}, [inView]);

	return <>
		<div ref={ref}>
			<h1 className="font-link" style={{"fontSize":"1.75rem"}} id={title} >{title}</h1>
			{children}
		</div>
	</>;
}