import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import OrderConfirmation from './pages/OrderConfirmation';
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
	{
		path:"/",
		element:<App/>
	},
	{	
		path:"/Confirmation",
		element:<OrderConfirmation />
	}
]);

ReactDOM.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>,
	document.getElementById("root")
);