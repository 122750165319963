import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';

const RestaurantSelect = ({ restaurants, restaurantSelected, setRestaurantSelected }) => {
  // Don't render the component if we're not in a development or staging environment
  if (process.env.REACT_APP_ENV !== 'development' && process.env.REACT_APP_ENV !== 'staging') {
    return null;
  }

  return (
    <FormControl style={{ 
      position: 'fixed', 
      display: 'flex',
      top: '100px', 
      right: '100px', 
      backgroundColor: 'white',
      zIndex: 1000, 
      padding: '10px', 
      borderRadius: '5px' 
    }}>
      <InputLabel id="restaurant-select-label">Restaurant</InputLabel>
      <Select
        labelId="restaurant-select-label"
        value={restaurantSelected}
        onChange={(event) => setRestaurantSelected(event.target.value)}
        onClose={() => {
    		document.body.style.overflow = 'auto'; // enables scrolling again
  		}}
      >
        {restaurants.map((restaurant) => (
          <MenuItem key={restaurant} value={restaurant}>
            {restaurant}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};


export default RestaurantSelect;