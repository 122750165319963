import React, {useEffect, useState} from "react";
import usePlacesAutocomplete, {getGeocode, getLatLng} from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';

import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import TextField from '@mui/material/TextField';

import List from '@mui/material/List';
import Input from '@mui/joy/Input';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

import Autocomplete from '@mui/joy/Autocomplete';


const PlaceAutocomplete = (props) => {

	const {
		ready,
		value,
		suggestions: {status, data},
		setValue,
		clearSuggestions
	} = usePlacesAutocomplete({
		requestOptions: { /* Define search scope here */ },
		debounce: 300
	});
	
	const registerRef = useOnclickOutside(() => {
		// When user clicks outsite of the componene, we can dismiss
		// the searched suggestions by calling this method
		clearSuggestions();
	});

	const handleInput = e => {
		// Update the keyword of the input element
		setValue(e.target.value);
		props.setAddress(value);
	};

	const handleSelect = ({ description }) => () => {
		// When user selects a place, we can replace the keyword without requests data from API
		// by settings the second parameter as "false"
		setValue(description, false);
		props.setAddress(description);
		clearSuggestions();

		// Get latitude and longitude via utility functions
		getGeocode({address: description})
			.then(results => getLatLng(results[0]))
			.then(({lat, lng}) => {
				console.log('? Coordinates: ', { lat, lng });
			}).catch(error => {
				console.log('? Error: ', error);
			});
	};

	const renderSuggestions = () => 
		data.map(suggestion => {
			const {
				id,
				structured_formatting: { main_text, secondary_text }
			} = suggestion;

			return (
				<ListItem
					key={id}
					onClick={handleSelect(suggestion)}
					sx={{borderBottom:"solid #e7ebf0 1px", cursor: "pointer"}}
					className="font-link hoverColor"
				>
					<strong>{main_text}</strong> <small>{"  " + secondary_text}</small>
				</ListItem>
			);
		});


	/*const options = () => data.map((suggestion) => {
		return {
		  ...suggestion,
		};
	});*/
	/*useEffect(() => {
		console.log(data);
	},[data]);*/

	const glassUIStyle = {
		//backdropFilter: 'blur(4px)',
		//backgroundColor: 'rgba(255, 255, 255, 0.1)',
		//border: '1px solid rgba(255, 255, 255, 0.2)'
	};

	const whiteTextStyle = {
		input: { color: 'white' },
	    color: 'white', // Text color
	    '& label': {
	      color: 'white', // Label color
	    },
	    '& .MuiInput-underline:before': {
	      borderBottomColor: 'white', // Underline color before focus
	    },
	    '& .MuiInput-underline:after': {
	      borderBottomColor: 'white', // Underline color after focus
	    },
	    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
	      borderBottomColor: 'white', // Underline color when hovering
	    },
	    '& .MuiFormHelperText-root': {
	      color: 'white', // Helper text color
	    },
	};


	// Conditionally apply glassUI styles
	const boxStyle = props.glassUI === "true" ? {...glassUIStyle, display: 'flex', flexDirection: 'column', m: 'auto', width: '100%'} : {display: 'flex', flexDirection: 'column', m: 'auto', width: '100%'};
	const textFieldStyle = props.glassUI === "true" ? {...glassUIStyle, ...whiteTextStyle, width:{xs:"100%",md:"18vw"}} : {width:{xs:"100%",md:"16vw"}};


	return (
	    <Box 
	      component="div"
	      sx={boxStyle}
	      alignItems="center"
	      justifyContent="center" 
	      ref={registerRef}
	    >
			<TextField 
				id="standard-basic" 
				label="Din Fulde Addresse" 
				variant="standard"
				value={value ? value : props.theValue}
				onChange={handleInput}
				disabled={!ready}
				sx={textFieldStyle}
				type="text"
			/>
			{ /* We can use the "status" to decide whether we should display the dropdown or not */ }
			{status === 'OK' && <List 
							      sx={{
							        width: '100%',
							        maxWidth: 360,
							        bgcolor: 'background.paper',
							        position: 'relative',
							        overflow: 'auto',
							        maxHeight: 300,
							        '& ul': { padding: 0 },
							      }}
							      subheader={<li />} onChange={handleSelect} >{renderSuggestions()}</List>}

		</Box>
	);

};

export default PlaceAutocomplete;