import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: '20px'
  },
  button: {
    margin: '10px',
    padding: '10px 20px',
    fontSize: '18px',
    fontFamily: 'Poppins, sans-serif'
  },
  dialogText: {
    fontSize: '20px',
    fontFamily: 'Poppins, sans-serif',
    marginBottom: '20px',
  },
  dialogTitle: {
    fontSize: '24px',
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 'bold',
  }
});

function AgeCheckDialog() {
  const [open, setOpen] = useState(true);
  const classes = useStyles();

  // Check if the user is an adult
  const handleAgeCheck = (isAdult) => {
    if (isAdult) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (open) {
      document.body.style.overflowY = 'scroll';
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
    } else {
      document.body.style.overflowY = 'unset';
      document.body.style.position = 'unset';
    }

    return () => {
      document.body.style.overflowY = 'unset';
      document.body.style.position = 'unset';
    };
  }, [open]);


  return (
    <Dialog
      open={open}
      onClose={() => {}}
      aria-labelledby="age-verification-dialog-title"
      aria-describedby="age-verification-dialog-description"
    >
      <DialogTitle id="age-verification-dialog-title" style={{textAlign: 'center'}} className={classes.dialogTitle}>
        {"Aldersverifikation"}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" align='center' id="age-verification-dialog-description" className={classes.dialogText}>
          Du skal være 18 eller derover for at få adgang til dette indhold. Er du 18 eller ældre?
        </Typography>
        <div className={classes.buttonContainer}>
          <Button variant="outlined" color="primary" className={classes.button} onClick={() => handleAgeCheck(true)}>
            Ja
          </Button>
          <Button variant="outlined" color="secondary" className={classes.button} onClick={() => handleAgeCheck(false)}>
            Nej
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default AgeCheckDialog;
