export const initGA = async (myTrackingID) => {
  if (myTrackingID) {
    window.dataLayer = window.dataLayer || [];
    window.gtag = function () {
      window.dataLayer.push(arguments);
    };
    window.gtag('js', new Date());
    window.gtag('config', myTrackingID);
  }
};

export const logPageView = (trackingID) => {
  if (typeof window.gtag === 'function') {
    window.gtag('config', trackingID, {
      page_path: window.location.pathname,
    });
  }
};
