import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';

const theme = {
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
    grey: {
      300: '#e0e0e0',
    },
  },
};

const ColorCircularProgress = styled(CircularProgress)`
  &.MuiCircularProgress-colorPrimary {
    color: ${theme.palette.secondary.main};
  }
`;

const BorderLinearProgress = styled(LinearProgress)`
  height: 10px;
  border-radius: 5px;
  background-color: ${theme.palette.grey[300]};
  & .MuiLinearProgress-barColorPrimary {
    background-color: ${theme.palette.primary.main};
  }
`;

const LoadingAnimation = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      gap: 2,
    }}
  >
    <Typography variant="h4" component="div">
      Loading...
    </Typography>
    <ColorCircularProgress />
    <BorderLinearProgress variant="determinate" value={50} />
  </Box>
);

export default LoadingAnimation;
