import React from 'react';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import FastfoodIcon from '@material-ui/icons/Fastfood';

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column",
    },
  },
  row: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "black",
    marginRight: "2.0rem",
    "& > svg": {
      marginRight: theme.spacing(2)
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2)
    }
  }
}));

function Discounts(props) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Container maxWidth="lg" className={classes.container} >
      <div className={classes.row}>
        <FastfoodIcon />
        <Typography variant="h6" style={{ fontSize: "1.5rem", fontWeight: "bold" }} >{props.discountText1}</Typography>
      </div>
      <div className={classes.row}>
        <CardGiftcardIcon />
        <Typography variant="h6" style={{ fontSize: "1.5rem", fontWeight: "bold" }} >{props.discountText2}</Typography>
      </div>
    </Container>
  );
}

export default Discounts;