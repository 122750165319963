import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import StripeCheckoutForm from "./StripeCheckOutForm";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

export default function StripeCheckOut(props) {
	const [clientSecret, setClientSecret] = useState("");

	const items = props.items;
	const orderData = props.orderData;
	console.log(items);

	useEffect(() => {
		console.log({ items: items, orderData: orderData });
	    // Create PaymentIntent as soon as the page loads
	    fetch( process.env.REACT_APP_API_ADDRESS + "/create-payment-intent", {
	      method: "POST",
	      headers: { "Content-Type": "application/json" },
	      body: JSON.stringify({ items: items, orderData: orderData, restaurantId: props.restaurantId }),
	    })
	      .then((res) => res.json())
	      .then((data) => setClientSecret(data.clientSecret))
	      .catch((err) => props.setErr(err.message));
	}, []);

	const appearance = {
		theme: 'flat',
	};
	const options = {
		clientSecret,
		appearance
	};

	return (
		<div className="App">
			{clientSecret && (
				<Elements options={options} stripe={stripePromise}>
					<StripeCheckoutForm restaurantId={props.restaurantId} />	
				</Elements>
			)}
		</div>
		);
}