import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import '../App.css';
import OrderConfirmationImg from './OrderConfirmationImg.png';

//#57fdfb

const OrderConfirmation = () => {
  return (
    <Box component="div" sx={{ backgroundColor: "#ffffff00" }}>
    	<Box
			component="div"
			style={{
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			backgroundColor: '#57fdfd',
			zIndex: -1,
      height: "100vh",
      width: "100vw",
			}}
		/>
      <Box
        component="div"
        sx={{
          position: 'absolute',
          top: {xs:"0%",md:'25%'},
          left: {xs:"0%",md:'25%'},
          width: {xs:"100%",md:'50%'},
          height: {xs:'100%',md:'50%'},
          backgroundImage: `url(${OrderConfirmationImg})`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat:"no-repeat",
          zIndex:-1,
        }}
      />
      <Box
        component="div"
        className="res-name"
        sx={{
          fontSize: { xs: '2em', md: '5em' },
          textAlign: 'center',
          color: 'white',
          textShadow: '0px 0px 20px black',
	      display: "flex",
	      flexDirection: "column",
          height:"30vh"
        }}
        alignItems="center"
        justifyContent="center"
      >
        Tak for din bestilling.
       	<br/> 
        Du har fået en sms med din ordre.
      </Box>


      <Box
        component="div"
        className="res-name"
        sx={{
          fontSize: { xs: '2em', md: '3em' },
          textAlign: 'center',
          color: 'white',
          textShadow: '0px 0px 20px black',
	      display: "flex",
	      flexDirection: "column",
          height:"30vh",
          marginTop:"40vh",
        }}
        alignItems="center"
        justifyContent="center"
      >
        Nu kan du læne dig tilbage og slappe af. <br/> Vi arbejder på at få din mad leveret til dig så hurtigt som muligt!
      </Box>
    </Box>
  );
};

export default OrderConfirmation;




