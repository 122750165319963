import React, { useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { green } from '@mui/material/colors';

export default function CartElements(props) {
  const { items, totalPrice, deliveryDiscount, delivery, deliveryPrice, discountRate } = props;

  const glassUIStyle = {
    margin: 1,
    backdropFilter: 'blur(4px)',
    background: 'linear-gradient(to right, white 20%, rgba(255,255,255,1) 10%, rgba(255, 255, 255, 0.000) 150%)',
    //backgroundColor: 'rgba(255, 255, 255, 0.1)',
    border: '1px solid rgba(255, 255, 255, 0.2)',
  };


  useEffect(() => {
    console.log(items);
  }, [items]);

  function deleteItem(id) {
    props.onDelete(id);
  }

  const renderItemOptions = (options) => (
    <div>
      <Typography variant="subtitle2" component="span">
        Options:
      </Typography>
      {options.map((option) => (
        <Typography key={option.optionId} variant="body2" component="div">
          {option.optionKindName}: {option.optionTitle} - {option.optionPrice} kr
        </Typography>
      ))}
    </div>
  );

  const renderItemExtras = (extras) => {
    const groupedExtras = {};

    extras.forEach((extra) => {
      if (!groupedExtras[extra.extraKindId]) {
        groupedExtras[extra.extraKindId] = {
          kindName: extra.extraKindName,
          extras: [],
        };
      }
      groupedExtras[extra.extraKindId].extras.push(extra);
    });

    return (
      <div>
        <Typography variant="subtitle2" component="span">
          Extras:
        </Typography>
        {Object.keys(groupedExtras).map((extraKindId) => (
          <div key={extraKindId}>
            <Typography variant="body2" component="div">
              {groupedExtras[extraKindId].kindName}:
            </Typography>
            {groupedExtras[extraKindId].extras.map((extra) => (
              <Typography key={extra.extraId} variant="body2" component="div" style={{ marginLeft: '1rem' }}>
                {extra.extraTitle} - {extra.extraPrice} kr
              </Typography>
            ))}
          </div>
        ))}
      </div>
    );
  };


  return (
    <List
      sx={props.glassUI === "true" ? {
        '&.MuiList-root': {  // Increase specificity
          ...glassUIStyle,
          borderRadius: 10,
        },
        width: '100%',
        maxWidth: 360,
        bgcolor: 'background.paper',
      } : {
        width: '100%',
        maxWidth: 360,
        bgcolor: 'background.paper',
      }}
    >
      <div style={{ maxHeight: '30vh', overflow: 'auto' }}>
        {items.map((item, index) => (
          <>
            <ListItem key={item.id}>
              <ListItemAvatar>
                <img src={`https://storage.googleapis.com/tas-images/MenuImgs/${item.food.img}`} style={{width:"60px"}} alt={item.food.title} />
              </ListItemAvatar>
              <ListItemText
                primary={item.food.title}
                secondary={
                  <>
                    {renderItemOptions(item.food.options)}
                    {renderItemExtras(item.food.extras)}
                  </>
                }
              />
              <ListItemText
                primary={
                  <Typography
                    className="font-link"
                    sx={{ color: '#009de0', fontWeight: 'bold' }}
                    variant="subtitle2"
                    align="right"
                    component="div"
                  >
                    {item.price + ',-'}
                  </Typography>
                }
              />
              <ListItemAvatar
                sx={{ bgcolor: green[0] }}
                onClick={() => {
                  deleteItem(item.id);
                }}
              >
                <DeleteForeverIcon sx={{ float: 'right' }} />
              </ListItemAvatar>
            </ListItem>
            <Divider variant="inset" component="li" />
          </>
        ))}
      </div>

      {delivery ? (
        <ListItem>
          <ListItemAvatar></ListItemAvatar>
          {deliveryDiscount ? (
            <>
              <ListItemText
                primary={
                  <Typography
                    className="font-link"
                    sx={{
                      textDecoration: 'line-through',
                      color: 'red',
                      fontWeight: 'bold',
                    }}
                    variant="subtitle2"
                    component="div"
                  >
                    Levering
                  </Typography>
                }
              />
              <ListItemText
                primary={
                  <Typography
                    className="font-link"
                    sx={{
                      textDecoration: 'line-through',
                      color: 'red',
                      fontWeight: 'bold',
                    }}
                    variant="subtitle2"
                    align="right"
                    component="div"
                  >
                    {deliveryPrice} DKK
                  </Typography>
                }
              />
            </>
          ) : (
            <>
              <ListItemText
                primary={
                  <Typography
                    className="font-link"
                    sx={{ color: '#009de0', fontWeight: 'bold' }}
                    variant="subtitle2"
                    component="div"
                  >
                    Levering
                  </Typography>
                }
              />
              <ListItemText
                primary={
                  <Typography
                    className="font-link"
                    sx={{ color: '#009de0', fontWeight: 'bold' }}
                    variant="subtitle2"
                    align="right"
                    component="div"
                  >
                    {deliveryPrice} DKK
                  </Typography>
                }
              />
            </>
          )}
        </ListItem>
      ) : (
        ''
      )}


      {items.length ?<ListItem>
          <ListItemAvatar>
          </ListItemAvatar>

          <ListItemText
            primary={
              <Typography className="font-link" sx={{color:"#009de0", fontWeight:"bold"}} variant="subtitle2" color="text.secondary" component="div">
              Håndteringsgebyr
              </Typography>
            }
      />
          <ListItemText
            primary={
              <Typography className="font-link" sx={{color:"#009de0", fontWeight:"bold"}} variant="subtitle2" align="right" color="text.secondary" component="div">
                {3} DKK
              </Typography>
            }
      />
      </ListItem>
      :
      ""}


      {items.length && discountRate ?<ListItem>
          <ListItemAvatar>
          </ListItemAvatar>

          <ListItemText
            primary={
              <Typography className="font-link" sx={{color:"#009de0", fontWeight:"bold"}} variant="subtitle2" color="text.secondary" component="div">
              {discountRate}% RABBAT
              </Typography>
            }
      />
          <ListItemText
            primary={
              <Typography className="font-link" sx={{color:"#009de0", fontWeight:"bold"}} variant="subtitle2" align="right" color="text.secondary" component="div">
                -{discountRate}%
              </Typography>
            }
      />
      </ListItem>
      :
      ""}

      {items.length && <ListItem>
          <ListItemAvatar></ListItemAvatar>
          <ListItemText
            primary={
              <Typography
                className="font-link"
                sx={{ color: '#009de0', fontWeight: 'bold' }}
                variant="subtitle2"
                component="div"
              >
                Total
              </Typography>
            }
          />
          <ListItemText
            primary={
              <Typography
                className="font-link"
                sx={{ color: '#009de0', fontWeight: 'bold' }}
                variant="subtitle2"
                align="right"
                component="div"
              >
                {totalPrice + ' DKK'}
              </Typography>
            }
          />
        </ListItem>}
    </List>
  );
}
