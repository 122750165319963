import Typography from '@material-ui/core/Typography';
import React, { useEffect ,useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10rem',
    color: '#ccc',
  },
});

function OpeningHoursDialog(props) {
  const [open, setOpen] = useState(true);
  const [isOpen, setIsOpen] = useState(null)

  useEffect(() => {
    if(props.isOpen){
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  },[props.isOpen]);
  const classes = useStyles();

  return (
    <>
        <Dialog
          open={(!isOpen && open) ? true : false}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{textAlign: 'center'}}>
            {"Bestillinger er lukket"}
          </DialogTitle>
          <DialogContent>
            <div className={classes.icon}>
              <HourglassEmptyIcon fontSize="large" color="primary" style={{ marginRight: '10px' }} />
            </div>
            <Typography variant="body1" align='center' id="alert-dialog-description" >
              {props.closeMsg}
            </Typography>
            <div style={{textAlign: 'center'}}>
              <Button variant="outlined" color="primary" onClick={() => setOpen(false)}>
                Ok 
              </Button>
            </div>
          </DialogContent>
        </Dialog>
    </>
  );
}

export default OpeningHoursDialog;
